export default interface User {
  surname: string;
  name: string;
  emailAddress: string;
  userType: string;
  userId?: string;
  isDeleted?: boolean;
}

export enum UserRoles {
  cleaning = 1,
  technician,
  administrator,
}

export enum UserFieldsNames {
  Name = 'name',
  Surname = 'surname',
  EmailAddress = 'emailAddress',
  UserType = 'userType',
}
