import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { backButtonTheme } from '../../theme';
import { Direction } from '../../types/general.types';

export interface GoToPreviousPageButtonProps {
  direction?: Direction;
  navigateTo?: string;
}

export default function GoToPreviousPageButton({
  direction = Direction.RIGHT,
  navigateTo,
}: GoToPreviousPageButtonProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = () => {
    if (navigateTo) {
      navigate(navigateTo);
    } else {
      navigate(-1);
    }
  };

  return (
    <Stack
      direction='row'
      justifyContent={direction === Direction.LEFT ? 'start' : 'end'}
      sx={{ display: 'flex' }}
    >
      <ThemeProvider theme={backButtonTheme}>
        <Button
          variant='contained'
          onClick={handleNavigate}
          sx={{
            textTransform: 'capitalize',
          }}
          size='medium'
        >
          <ArrowBackIcon fontSize='small' sx={{ mr: 1 }} /> {t('back')}
        </Button>
      </ThemeProvider>
    </Stack>
  );
}
