import { Locker } from './locker.types';

export enum PackageState {
  pre_registered = 1,
  registered,
  downloaded_to_locker,
  in_work,
  on_hold,
  finalized,
  waiting_drawer_reassignment,
  drawer_reassignment_failed,
  package_missing,
  session_expired_door_closed,
  open_door,
  canceled,
  package_too_big,
  payment_failed,
}

export enum AWBType {
  standard = 1,
  'redirected',
  returned,
}

export enum Wrokflow {
  drawer_reservation = 1,
  package_drop_off_courier,
  package_pick_up_courier,
  package_drop_off_partner,
  package_drop_off_customer,
  package_pick_up_customer,
}

export interface old_Package {
  packageId: string;
  packageState: number;
  awb: string;
  awbType: number;
  lockerId: string;
  drawerId: string;
  workflow: number;
  expirationDate: string;
  clientPIN: string;
  courierCode: string;
  createdAt: string;
  height: number;
  length: number;
  width: number;
  packageSnapshots: [];
  updatedAt: string;
}

export enum PACKAGE_HISTORY_TYPE {
  LOCKER = 'locker',
  GLOBAL = 'global',
}

export interface PACKAGE_PAYMENTS {
  amount?: number;
  type?: number;
}

export interface Package {
  awb: string;
  awbType: number;
  clientPIN: string;
  courierCode: string;
  createdAt: string;
  customerId: string;
  drawerId: string;
  expirationDate: string;
  height: number;
  length: number;
  lockerCode: string;
  lockerId: string;
  packageId: string;
  packageSnapshots: [];
  packageState: number;
  payments: PACKAGE_PAYMENTS[];
  updatedAt: string;
  width: number;
  workflow: number;
  locker: Locker;
}

export enum PACKAGE_PAYMENT_TYPE {
  TRANSPORTATION = 1,
  REINBURSEMENT = 2,
}

export interface Payment {
  amount: number;
  type: PACKAGE_PAYMENT_TYPE;
}

export const WorkflowToLabel: Record<number, string> = {
  1: 'drawer_reservation',
  2: 'package_drop_off_courier',
  3: 'package_pick_up_courier',
  4: 'package_drop_off_partner',
  5: 'package_drop_off_customer',
  6: 'package_pick_up_customer',
};

export const PackageStateToLabel: Record<number, string> = {
  1: 'pre_registered',
  2: 'registered',
  3: 'downloaded_to_locker',
  4: 'in_work',
  5: 'on_hold',
  6: 'finalized',
  7: 'waiting_drawer_reassignment',
  8: 'drawer_reassignment_failed',
  9: 'package_missing',
  10: 'session_expired_door_closed',
  11: 'open_door',
  12: 'canceled',
  13: 'package_too_big',
  14: 'payment_failed',
};
