import React from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DataGridLegendProps {
  legendList: { label: string; bgColorClass: string }[];
}

export default function DataGridLegend({ legendList }: DataGridLegendProps) {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5, mt: 2, mb: 2 }}>
      {legendList.map((el) => (
        <Stack direction='row' spacing={1} alignItems='center' key={el.label}>
          <Box className={`color-legend ${el.bgColorClass}`} />
          <Box>{t(el.label)}</Box>
        </Stack>
      ))}
    </Box>
  );
}
