import {
  MANAGEMENT_ACCESS_TOKEN_KEY,
  MANAGEMENT_COUNTRY_CODE_STOARGE_KEY,
  MANAGEMENT_LOCALE_STOARGE_KEY,
  MANAGEMENT_TIMEZONE_STOARGE_KEY,
} from '../constants';
import { getSessionStorageItem, removeSessionStorageKey } from '../utils';

export const getUser = () => {
  const authUser = getSessionStorageItem(MANAGEMENT_ACCESS_TOKEN_KEY);
  return authUser ? JSON.parse(authUser) : null;
};

export const logout = () => {
  removeSessionStorageKey(MANAGEMENT_ACCESS_TOKEN_KEY);
  removeSessionStorageKey(MANAGEMENT_LOCALE_STOARGE_KEY);
  removeSessionStorageKey(MANAGEMENT_TIMEZONE_STOARGE_KEY);
  removeSessionStorageKey(MANAGEMENT_COUNTRY_CODE_STOARGE_KEY);
  window.location.href = '/';
};
