import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { BaseSyntheticEvent, useState } from 'react';

interface PasswordFieldProps {
  value: string;
  onChange: (e: BaseSyntheticEvent) => void;
  hasError: boolean;
  errMsg: string;
  label: string;
  name: string;
}

export default function PasswordField({
  value,
  onChange,
  hasError,
  errMsg,
  label,
  name,
}: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography
        sx={{ width: '100%', color: '#666666', marginBottom: '0.5rem' }}
      >
        {label}
      </Typography>
      <TextField
        sx={{
          mb: 1.5,
          width: '100%',
        }}
        value={value}
        name={name}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        required
        error={hasError}
        helperText={hasError && errMsg}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
