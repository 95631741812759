import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
  message: string;
  status: boolean;
  handleRejection: () => void;
  handleConfirmation: () => void;
}

export default function ConfirmationDialog({
  message,
  status,
  handleRejection,
  handleConfirmation,
}: ConfirmationDialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={status}
      className='customDialog'
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      fullWidth
      maxWidth='xs'
      PaperProps={{
        style: { borderRadius: 5 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'warning.main',
        }}
      >
        <HelpIcon
          sx={{ color: 'white', width: '2em', height: '2em', margin: 1 }}
          fontSize='large'
        />
      </Box>
      <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
        {message}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={handleRejection}
          sx={{ textTransform: 'capitalize' }}
        >
          {t('cancel')}
        </Button>
        <Button
          variant='contained'
          size='small'
          onClick={handleConfirmation}
          sx={{ textTransform: 'capitalize' }}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
