import User from './user.types';

export interface ActionLogs {
  actionLogId: number;
  actionLogType: number;
  authorizationId: string;
  createdAt: string;
  drawerId: string | null;
  drawerIsDoorOpen: boolean;
  drawerState: number | null;
  lockerId: string;
  lockerState: number;
}

export interface Authorization {
  authorizationId?: string;
  authorizationType: number;
  validFrom: string;
  validTo: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  lockerCode: string;
  drawerId?: string;
  actionLogs?: ActionLogs[];
  user: User | null;
  allowSmartKeyOperation: boolean;
}

export interface AuthorizationData {
  startDate: Date | null;
  endDate: Date | null;
  authorizationType: number;
  user: User | null;
  lockerCode?: string;
  showLockerCode: boolean;
  allowSmartKeyOperation: boolean;
}

export enum AuthorizationType {
  cleaning = 1,
  technical_maintenance,
}

export interface AuthorizationBody {
  authorizationType: number;
  validFrom: string | number;
  validTo: string | number;
  lockerCode: string | null;
  userId?: string;
  drawerId?: string;
  authorizationId?: string;
  allowSmartKeyOperation?: boolean;
}

export enum ActionLogType {
  authentication = 1,
  cleaning,
  maintenance_started,
  maintenance_completed_successfully,
  maintenance_ended_with_error,
  disconnection,
}

export enum ACTIVITY_STATE {
  not_started = 'not_started',
  completed = 'completed',
  in_progress = 'in_progress',
}

export interface AddActivityOption {
  id: number;
  value: boolean;
  label: string;
}

export enum ACTIVITY_TABLE_TYPE {
  global = 'global',
  user = 'user',
}
