import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetAuthorizationsByUserId } from '../../services/requests';
import GoToPreviousPageButton from '../common/GoToPreviousPageButton';
import ActivitiesTable from '../common/ActivitiesTable';
import { ACTIVITY_TABLE_TYPE } from '../../types/activity.types';

export default function UserActivities() {
  const { userId } = useParams();

  return (
    <div>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <GoToPreviousPageButton />
      </Box>
      <ActivitiesTable
        getData={useGetAuthorizationsByUserId}
        type={ACTIVITY_TABLE_TYPE.user}
        id={userId as string}
      />
    </div>
  );
}
