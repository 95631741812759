import { GridRowId } from '@mui/x-data-grid';
import { CONNECTED_DRAWER_HEIGHTS } from '../constants';

export interface County {
  countyId: string;
  name: string;
  latitude?: string;
  longitude?: string;
}
export interface Locality {
  localityId: string;
  name: string;
  aditionalInformation?: string;
  latitude?: string;
  longitude?: string;
  adM2Name?: string;
}

export interface Address {
  addressId?: string;
  county?: County;
  locality?: Locality;
  street?: string;
  buildingNumber?: string;
  floor?: string;
  postCode?: string;
  description?: string;
}
export interface AppVersion {
  version?: string;
  appVersionId?: string;
}

export interface AutonomousLockerInformation {
  hasAlarm: boolean;
  hasCamera: boolean;
  hasGsm: boolean;
  hasLights: boolean;
  hasScreen: boolean;
  needsSync: boolean;
  alarmState: boolean;
  lightState: boolean;
}

export interface Locker {
  name: string;
  lockerCode: string;
  lockerState: number | string;
  description: string;
  mac?: string | null;
  appVersion?: AppVersion;
  targetVersion?: AppVersion;
  latitude: number | string;
  longitude: number | string;
  address?: Address;
  targetVersionId?: string;
  posActive: string | boolean;
  lockerType: number | string;
  lockerLocationType: number | string;
  schedule?: string;
  clusterOrder?: number;
  clusterId?: string;
  isDeleted?: boolean;
  autonomousLockerInformation?: AutonomousLockerInformation;
  hasPosSystem?: boolean;
}

export interface DrawerType {
  drawerTypeId: string;
  size: number;
  width: number;
  height: number;
  length: number;
  label: string;
}

export interface DrawerTypeCount {
  drawerTypeId: string;
  count: number;
}

export interface DrawersToUpdate {
  drawerId: GridRowId;
  drawerState: number;
}

export interface DrawerPosition {
  column: string;
  row: string;
}

export interface IDrawer {
  id: string;
  size: number;
  columnId?: string;
  customerId?: string;
  companyName?: string;
  position?: DrawerPosition;
  inUse?: boolean;
  state?: number;
  isCentralUnit?: boolean;
  columnPosition?: string;
  board?: string;
}

export interface Drawer {
  board: string;
  column: string;
  drawerId: string;
  drawerState: number;
  drawerType: DrawerType;
  isDeleted: boolean;
  isDoorOpen: boolean;
  row: string;
  id?: string;
  customerId?: string;
  companyName?: string;
  isCentralUnit?: boolean;
  size: number;
  columnId: string;
}
export interface DrawerToSave {
  row: string;
  column: string;
  board: string;
  drawerType: {
    drawerTypeId: string;
  };
  id: string;
  drawerState: number;
  isCentralUnit?: boolean;
  size?: number;
  isDeleted?: boolean;
  columnId?: string;
}
export interface DrawerToDelete {
  drawerId: string;
  isDeleted: boolean;
}
export interface DrawerDBReady {
  drawerId?: string;
  isDeleted?: boolean;
  row?: string;
  board?: string;
  column?: string;
}

export enum COLUMN_POSITION {
  Left = 'L',
  Right = 'R',
}

export enum DRAWER_SIZE {
  S = 1,
  M = 2,
  L = 3,
}
export enum MAP_DRAWER_SIZE_TO_DRAWER_TYPE {
  'small' = 'S',
  'medium' = 'M',
  'large' = 'L',
}

export enum DrawerState {
  active = 1,
  inactive,
  in_use,
  'maintenance_required',
  'cleaning_required',
  'maintenance_required_in_use',
  'cleaning_in_progress',
  'maintenance_in_progress',
}

export enum DrawerTypeSize {
  small = 1,
  medium,
  large,
}

export enum OnlineStatus {
  online = 1,
  offline = 2,
}

export enum LockerState {
  'active' = 1,
  'inactive',
  'sync_issue',
  'cleaning_in_progress',
  'maintenance_in_progress',
}

export interface LockerSettingsDTO {
  settingId: string;
  keyDisplayName: string;
  key: string;
  value: string;
  validationRegex: string;
  validationMessage: string;
  lockerType: number;
}

export interface IColumn {
  id: string;
  position: string;
  isModified?: boolean;
  board?: string;
  isSaved?: boolean;
  toBeDeleted?: boolean;
}

export enum LOCKER_TYPE {
  'connected_locker' = 1,
  'autonomous_locker',
}
export enum ADD_CONNECTED_LOCKER_WIZARD_STEPS {
  'SELECT_LOCKER_TYPE' = 0,
  'LOCKER_SETTINGS',
  'HARDWARE_CAPABILITIES',
  'ADD_IDENTIFIER',
  'SELECT_CENTRAL_UNIT_TYPE',
  'LOCKER_STRUCTURE',
}
export enum ADD_AUTONOMOUS_LOCKER_WIZARD_STEPS {
  'SELECT_LOCKER_TYPE' = 0,
  'LOCKER_SETTINGS',
  'HARDWARE_CAPABILITIES',
  'SELECT_CENTRAL_UNIT_TYPE',
  'LOCKER_STRUCTURE',
}

export interface LockerCommand {
  lockerCommandId: string;
  lockerCommandType: string;
  eventId: string;
  drawerId: string;
  createdAt: string;
  commandExpirationDate: string;
  executionStatus: number;
}

export interface AutonomousLockerCapabilities {
  hasLights: boolean;
  hasAlarm: boolean;
  hasCamera: boolean;
  hasGsm: boolean;
  hasScreen: boolean;
}

export const DrawerStateToLabel: Record<number, string> = {
  1: 'active',
  2: 'inactive',
  3: 'in_use',
  4: 'maintenance_required',
  5: 'cleaning_required',
  6: 'maintenance_required_in_use',
  7: 'cleaning_in_progress',
  8: 'maintenance_in_progress',
};

export const DrawerSizeToLabel: Record<number, string> = {
  1: 'small_drawers',
  2: 'medium_drawers',
  3: 'large_drawers',
};

export interface DrawerStructure {
  size: number;
  quantity: number;
  isCentralUnit?: boolean;
}

export interface LockerColumnTemplate {
  id: string;
  structure: DrawerStructure[];
}
export interface LockerTemplate {
  type: string;
  label: string;
  columns: LockerColumnTemplate[];
}

export interface DrawerNew {
  size: number;
  id: string;
  columnId: string;
  isCentralUnit?: boolean;
}

export type DrawerSize = {
  [key in '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10']: string;
};

export interface ColumnTemplate {
  type: string;
  structure: DrawerStructure[];
}

export type LockerColumnMap = Record<string, Drawer[]>;

export type ConnectedDrawerHeightKey = keyof typeof CONNECTED_DRAWER_HEIGHTS;
export type ConnectedDrawerHeightValue =
  typeof CONNECTED_DRAWER_HEIGHTS[ConnectedDrawerHeightKey];
