import { GridSortModel } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { getLastWeeksDate } from '.';

import {
  DEFAULT_ITEMS_PER_PAGE,
  PAGINATION_INITIAL_PAGE_INDEX,
} from '../constants';
import { DateType, IPagination, PaginationInfo } from '../types/general.types';

export default function useStartAndEndDate() {
  // set the start date - end date interval to one week by default
  const [startDate, setStart] = useState<DateType>(getLastWeeksDate());
  const [endDate, setEnd] = useState<DateType>(new Date());

  const setStartDate = (newValue: Date | null) => {
    setStart(newValue);
  };

  const setEndDate = (newValue: Date | null) => {
    setEnd(newValue);
  };

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };
}

type SetQuery<T> = (item: T) => void;

interface UseSortDataGridParams {
  setQuerySort: SetQuery<string>;
  setQueryOrderBy: SetQuery<string>;
}

export function useSortDataGrid({
  setQuerySort,
  setQueryOrderBy,
}: UseSortDataGridParams) {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: '',
      sort: null,
    },
  ]);

  const onSortModelChange = (model: GridSortModel) => {
    if (model[0]) {
      setQueryOrderBy(model[0].field);
      setQuerySort(model[0].sort as string);
    } else {
      setQueryOrderBy('');
      setQuerySort('');
    }
    setSortModel(model);
  };

  return {
    onSortModelChange,
    sortModel,
  };
}

interface PaginationDataGridParams {
  data: { data: AxiosResponse; paginationInformation: PaginationInfo };
  setQueryPageSize: SetQuery<number>;
  setQueryPageNumber: SetQuery<number>;
}

export function usePaginationDataGrid({
  data,
  setQueryPageSize,
  setQueryPageNumber,
}: PaginationDataGridParams) {
  const [page, setPage] = useState(PAGINATION_INITIAL_PAGE_INDEX);
  let pagination: IPagination | {} = {};
  if (data) {
    const { paginationInformation } = data;
    pagination = { ...paginationInformation };
  }

  const onChangePageSize = (pageSize: number) => {
    setQueryPageSize(pageSize);
  };

  const onPageChange = (pageIndex: number) => {
    setPage(pageIndex);
    setQueryPageNumber(pageIndex + 1);
  };

  return {
    page,
    pagination,
    onChangePageSize,
    onPageChange,
  };
}

export function useFrontendPaginationDataGrid() {
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ITEMS_PER_PAGE);
  const [page, setPage] = useState(0);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  return {
    pageSize,
    page,
    handleChangePageSize,
    handleChangePage,
  };
}
