import { useQuery, useMutation, useQueryClient } from 'react-query';
import { GridRowId } from '@mui/x-data-grid';
import axios from './interceptors';
import User from '../types/user.types';
import {
  Drawer,
  DrawerDBReady,
  DrawerToSave,
  Locker,
  DrawersToUpdate,
  LockerSettingsDTO,
  DrawerTypeCount,
} from '../types/locker.types';
import {
  getCleanParams,
  getCountryName,
  getLocale,
  getQueryString,
  getSessionStorageItem,
  getStartOfTheDayEndOfTheDayUTC,
  getTimezone,
} from '../utils';
import {
  AssignClusterParams,
  ChangePasswordParams,
  CreateLockerCommandParams,
  ForgotPasswordParams,
  KeyValueParams,
  PostLoginParams,
  QueryParams,
  RemoveClusterAssignationParams,
} from '../types/general.types';
import { AuthorizationBody } from '../types/activity.types';
import { Customer } from '../types/customer.types';
import { Cluster } from '../types/cluster.type';
import { MANAGEMENT_COUNTRY_CODE_STOARGE_KEY } from '../constants';

// lockers endpoints
export const useLockers = (queryParams: QueryParams) => {
  return useQuery(
    ['lockers', queryParams],
    async ({ queryKey }) => {
      const [, params] = queryKey;
      const queryString = getQueryString(params as QueryParams);
      const { data } = await axios.get(`/lockers?${queryString}`);
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

export const createLocker = (locker: Locker) => {
  return axios.post('/lockers', locker);
};
export const createDrawers = (params: {
  drawers: DrawerToSave[] | Drawer[] | DrawerDBReady[];
  lockerCode: string;
}) => {
  const { lockerCode, drawers } = params;
  return axios.post(`/lockers/${lockerCode}/drawers`, drawers);
};

export const useDrawerTypes = () => {
  return useQuery(
    ['drawerTypes'],
    async () => {
      const { data } = await axios.get('/drawerTypes');
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

export const useDrawers = (lockerCode: string, enabled = true) => {
  return useQuery(
    ['drawers', lockerCode],
    async () => {
      const { data } = await axios.get(`/lockers/${lockerCode}/drawers`);
      return data;
    },
    { enabled }
  );
};

// endpoints packages

export const usePackages = (queryParams: QueryParams) => {
  return useQuery(['packages', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/packages?${queryString}`);
    return data;
  });
};

export const useGetPackageByAWB = (AWB: string) => {
  return useQuery(
    ['package', AWB],
    async () => {
      const { data } = await axios.get(`/packages/${encodeURIComponent(AWB)}`);
      return data;
    },
    {
      enabled: false,
    }
  );
};

export const useGetPackageSnapshots = (packageId: string) => {
  return useQuery(['packageSnapshots', packageId], async () => {
    const { data } = await axios.get(`/packages/${packageId}/packageSnapshots`);
    return data;
  });
};

// users endpoints
export const useUsers = (queryParams: QueryParams) => {
  return useQuery(['users', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/users?${queryString}`);
    return data;
  });
};

export const useAllUsers = () => {
  return useQuery(['users'], async () => {
    const { data } = await axios.get(`/users`);
    return data;
  });
};

export const updateUserById = (user: User) => {
  const { userId, ...rest } = user;
  return axios.put(`/users/${userId}`, rest);
};

export const useGetUserById = (id: string) => {
  return useQuery(['user', id], async () => {
    const { data } = await axios.get(`/users/${id}`);
    return data;
  });
};

export const createUser = (user: User) => {
  return axios.post(`/users`, user);
};

export const deleteUserById = (params: { id: string; userToDelete: User }) => {
  const { id, userToDelete } = params;
  const response = axios.delete(`/users/${id}`, { data: userToDelete });
  return response;
};

export const useGetPackageBySnapshoyId = (id: number) => {
  return useQuery(['packageSnapshot', id], async () => {
    const { data } = await axios.get(`/packageSnapshots/${id}`);
    return data;
  });
};

export const useGetActionLogsByActivity = (id: number) => {
  return useQuery(['actionLogs', id], async () => {
    // call-ul ca lumea cand vom avea back end
    const { data } = await axios.get(`/actionLogs`);
    return data;
  });
};

export const useLocker = (lockerCode: string) => {
  return useQuery(['locker', lockerCode], async () => {
    const { data } = await axios.get(`/lockers/${lockerCode}`);
    return data;
  });
};

export const useLockerActivitiesHistory = (lockerCode: string) => {
  return useQuery(['locker-activities', lockerCode], async () => {
    const { data } = await axios.get(`/lockers/${lockerCode}/authorizations`);
    return data;
  });
};

export const useCreateLocker = () => {
  const queryClientInstance = useQueryClient();

  return useMutation((locker: Locker) => axios.post(`/lockers`, locker), {
    onSuccess: () => {
      queryClientInstance.invalidateQueries('lockers');
    },
  });
};

export const useUpdateLockerDrawers = (params: {
  lockerCode: string;
  drawersToUpdate: DrawersToUpdate[];
}) => {
  const { lockerCode, drawersToUpdate } = params;
  return axios.put(`/lockers/${lockerCode}/drawers`, drawersToUpdate);
};

export const deleteLockerByLockerCode = (lockerCode: string) => {
  return axios.delete(`/lockers/${lockerCode}`);
};

export const useGetLockerLogs = (
  lockerCode: string,
  logType: string,
  enabled: boolean
) => {
  return useQuery(
    ['locker-logs', lockerCode, logType],
    async () => {
      const endpoint = `/lockers/${lockerCode}/logs?logType=${logType}`;
      const { data } = await axios.get(endpoint);

      return data;
    },
    { enabled }
  );
};

export const useDowloadLockerLogs = (download: {
  lockerCode: string;
  fileName: string;
}) => {
  const { lockerCode, fileName } = download;
  return axios.get(`/lockers/${lockerCode}/logs/${fileName}`, {
    responseType: 'arraybuffer',
  });
};

export const useDownloadVideos = (queryParams: {
  lockerCode: string;
  startDate: string;
  endDate: string;
}) => {
  const { lockerCode, startDate, endDate } = queryParams;
  return axios.get(
    `/videos?startDate=${startDate}&endDate=${endDate}&lockerCode=${lockerCode}`,
    { responseType: 'arraybuffer' }
  );
};

export const useEvents = (queryParams: QueryParams) => {
  return useQuery(['events', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const { startDate, endDate } = params as QueryParams;
    const queryString = getQueryString({
      ...(params as QueryParams),
      ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
    });
    const { data } = await axios.get(`/events?${queryString}`);
    return data;
  });
};

export const useLockerIncidents = (queryParams: QueryParams) => {
  return useQuery(['locker/incidents', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const { startDate, endDate } = params as QueryParams;
    const queryString = getQueryString({
      ...(params as QueryParams),
      ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
    });
    const { data } = await axios.get(`/incidents/lockers?${queryString}`);
    return data;
  });
};

export const useDrawerIncidents = (queryParams: QueryParams) => {
  return useQuery(['drawer/incidents', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const { startDate, endDate } = params as QueryParams;
    const queryString = getQueryString({
      ...(params as QueryParams),
      ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
    });
    const { data } = await axios.get(`/incidents/drawers?${queryString}`);
    return data;
  });
};

export const usePackageIncidents = (queryParams: QueryParams) => {
  return useQuery(['package/incidents', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const { startDate, endDate } = params as QueryParams;
    const queryString = getQueryString({
      ...(params as QueryParams),
      ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
    });
    const { data } = await axios.get(`/incidents/packages?${queryString}`);
    return data;
  });
};

// --- activities endponits ----
export const useActivities = (queryParams: QueryParams) => {
  return useQuery(['authorizations', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/authorizations?${queryString}`);
    return data;
  });
};

export const useGetAuthorizationById = (id: string) => {
  return useQuery(['authorization', id], async () => {
    const { data } = await axios.get(`/authorizations/${id}`);
    return data;
  });
};
export const useGetAuthorizationsByUserId = (
  queryParams: QueryParams,
  id?: string
) => {
  return useQuery(
    ['user-authorizations', queryParams, id],
    async ({ queryKey }) => {
      const [, params] = queryKey;
      const queryString = getQueryString(params as QueryParams);
      const { data } = await axios.get(
        `/users/${id}/authorizations?${queryString}`
      );
      return data;
    }
  );
};

export const createAuthorization = (authorization: AuthorizationBody) => {
  return axios.post(`/authorizations`, authorization);
};

export const updateAuthorization = (authorization: AuthorizationBody) => {
  const { authorizationId, ...rest } = authorization;
  return axios.put(`/authorizations/${authorizationId}`, rest);
};

export const deleteActivityById = (id: string) => {
  return axios.delete(`/authorizations/${id}`);
};

export const updateLockerById = (data: Locker) => {
  return axios.put(`/lockers/${data.lockerCode}`, data);
};

export const useLockerPackages = (
  queryParams: QueryParams,
  lockerCode: string
) => {
  return useQuery(
    ['lockers/packages', queryParams, lockerCode],
    async ({ queryKey }) => {
      const [, params] = queryKey;
      const queryString = getQueryString(params as QueryParams);
      const { data } = await axios.get(
        `/lockers/${lockerCode}/packages?${queryString}`
      );
      return data;
    }
  );
};

export const useVideoMoments = (packageId: string) => {
  return useQuery(['videoMoments', packageId], async () => {
    const { data } = await axios.get(`/packages/${packageId}/video-moments`);
    return data;
  });
};

export const getDrawersByLockerId = (lockerId: string) => {
  return useQuery('drawers', async () => {
    const { data } = await axios.get(`lockers/${lockerId}`);
    return data;
  });
};

export const getLockerSettings = (lockerCode: string | undefined) => {
  return useQuery('settings', async () => {
    const { data } = await axios.get(`/lockers/${lockerCode}/locker-settings`);
    return data;
  });
};
export const useLockersDefaultSettings = () => {
  return useQuery('default-settings', async () => {
    const { data } = await axios.get('/defaultSettings');
    return data;
  });
};

export const updateLockersDefaultSettings = (requestParams: {
  settings: LockerSettingsDTO[];
}) => {
  const { settings } = requestParams;
  return axios.put('/defaultSettings', settings);
};

export const updateLockerSettings = (requestParams: {
  lockerCode: string | undefined;
  settings: LockerSettingsDTO[];
}) => {
  const { lockerCode, settings } = requestParams;
  return axios.put(`/lockers/${lockerCode}/locker-settings`, settings);
};

export const useCounties = () => {
  const countryCode = getSessionStorageItem(
    MANAGEMENT_COUNTRY_CODE_STOARGE_KEY
  );
  return useQuery(
    'counties',
    async () => {
      const { data } = await axios.get(`/counties/${countryCode}`);
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

export const useLocalitiesByCountyId = (countyId: string, enabled = true) => {
  return useQuery(
    `locality-${countyId}`,
    async () => {
      const { data } = await axios.get(`/counties/${countyId}/localities`);
      return data;
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAppVersions = (queryParams: QueryParams) => {
  return useQuery(
    ['app-versions', queryParams],
    async ({ queryKey }) => {
      const [, params] = queryKey;
      const queryString = getQueryString(params as QueryParams);
      const { data } = await axios.get(`/app-versions?${queryString}`);
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

export const uploadAppVersionFile = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/app-versions', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// customers endpoints
export const useCustomers = (queryParams: QueryParams) => {
  return useQuery(['customers', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/customers?${queryString}`);
    return data;
  });
};

export const useGetCustomerById = (id: string) => {
  return useQuery(
    ['customer', id],
    async () => {
      const { data } = await axios.get(`/customers/${id}`);
      return data;
    },
    { cacheTime: 0 }
  );
};

export const addCustomer = (customer: Customer) => {
  const countryCode = getSessionStorageItem(
    MANAGEMENT_COUNTRY_CODE_STOARGE_KEY
  );
  return axios.post(`/customers/${countryCode}`, customer);
};

export const updateCustomerById = (customer: Customer) => {
  const { customerId, ...rest } = customer;
  return axios.put(`/customers/${customerId}`, rest);
};

export const deleteCustomer = (customerId: string) => {
  return axios.delete(`/customers/${customerId}`);
};

export const activateCustomer = (customer: Customer) => {
  const { customerId, ...rest } = customer;
  return axios.put(`/customers/${customerId}`, { ...rest, isDeleted: false });
};

export const useGetCustomerLockers = (
  queryParams: QueryParams,
  customerId: string
) => {
  return useQuery(
    ['customer-lockers', queryParams, customerId],
    async ({ queryKey }) => {
      const [, params] = queryKey;
      const queryString = getQueryString(params as QueryParams);
      const { data } = await axios.get(
        `/customers/${customerId}?${queryString}`
      );
      return data;
    }
  );
};

export const assignLockerToCustomer = (
  lockerCode: GridRowId,
  customerId: string
) => {
  return axios.post(`/lockers/${lockerCode}/drawers/${customerId}`);
};
export const removeLockerFromCustomer = (
  lockerCode: GridRowId,
  customerId: string
) => {
  return axios.delete(`/lockers/${lockerCode}/drawers/${customerId}`);
};

export const assignDrawerToCustomer = (
  drawerId: string,
  customerId: string
) => {
  return axios.patch(`/drawers/${drawerId}`, [
    {
      op: 'add',
      path: '/customerId',
      value: customerId,
    },
  ]);
};

export const unassignDrawerFromCustomer = (
  drawerId: string,
  customerId: string
) => {
  return axios.patch(`/drawers/${drawerId}`, [
    {
      op: 'remove',
      path: '/customerId',
      value: customerId,
    },
  ]);
};

export const assignDrawersInFleet = (params: {
  customerId: string;
  drawerTypesCount: DrawerTypeCount[];
  lockerCodes: Array<GridRowId>;
}) => {
  const { customerId, lockerCodes, drawerTypesCount } = params;
  return axios.post(`/lockers/fleet-assignation/${customerId}`, {
    lockerCodes,
    drawerTypesCount,
  });
};

// clusters endpoints
export const useClusters = (queryParams: QueryParams) => {
  return useQuery(['clusters', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/clusters?${queryString}`);
    return data;
  });
};

export const createCluster = (cluster: Cluster) => {
  return axios.post('/clusters', cluster);
};

export const deleteCluster = (clusterId: string) => {
  return axios.delete(`/clusters/${clusterId}`);
};

export const useCluster = (clusterId: string) => {
  return useQuery(
    ['clusters', clusterId],
    async () => {
      const { data } = await axios.get(`/clusters/${clusterId}`);
      return data;
    },
    { cacheTime: 0 }
  );
};

export const editCluster = (cluster: Cluster) => {
  return axios.put(`/clusters`, cluster);
};

export const useAutonomousLockerConnectionData = (lockerCode: string) => {
  return useQuery(['connection-data', lockerCode], async () => {
    const { data } = await axios.get(
      `/lockers/${lockerCode}/autonomous-details`
    );
    return data;
  });
};

export const getReportInfo = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/fleet-global?${queryString}`);
  return data;
};

export const downloadReport = (params: {
  queryParams: QueryParams;
  reportType: number;
}) => {
  const { queryParams, reportType } = params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pageSize, pageNumber, ...rest } = queryParams;
  const queryString = getQueryString(rest);
  return axios.get(`/reports/download-fleed-load/${reportType}?${queryString}`);
};

export const getDrawersLoad = async (params: {
  queryParams: QueryParams;
  lockerCode: string;
}) => {
  const { queryParams, lockerCode } = params;
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(
    `/reports/fleet-load/${lockerCode}/drawers?${queryString}`
  );
  return data;
};

export const getClustersLoadReport = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`/reports/cluster-load?${queryString}`);
  return data;
};

export const getClusterLoadReport = async (params: {
  queryParams: QueryParams;
  clusterId: string;
}) => {
  const { queryParams, clusterId } = params;
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(
    `/reports/cluster-load/${clusterId}?${queryString}`
  );
  return data;
};

export const getFleetLoadReport = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/fleet-load?${queryString}`);
  return data;
};

export const getFleetUptimeReport = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/fleet-uptime?${queryString}`);
  return data;
};

export const getPackageTimeOnLockerReport = async (
  queryParams: QueryParams
) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/parcel-time?${queryString}`);
  return data;
};

export const downloadDrawersLoadReport = (params: {
  queryParams: QueryParams;
  lockerCode: string;
}) => {
  const { queryParams, lockerCode } = params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pageSize, pageNumber, ...rest } = queryParams;
  const queryString = getQueryString(rest);
  return axios.get(
    `/reports/download-drawer-load/${lockerCode}?${queryString}`
  );
};

export const downloadClustersLoadReport = (params: {
  queryParams: QueryParams;
}) => {
  const { queryParams } = params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pageSize, pageNumber, ...rest } = queryParams;
  const queryString = getQueryString(rest);
  return axios.get(`/reports/download-cluster-load?${queryString}`);
};

export const downloadClusterLoadReport = (params: {
  queryParams: QueryParams;
  clusterId: string;
}) => {
  const { queryParams, clusterId } = params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pageSize, pageNumber, ...rest } = queryParams;
  const queryString = getQueryString(rest);
  return axios.get(
    `/reports/download-cluster-load/${clusterId}?${queryString}`
  );
};

export const getCustomerBillingReport = async (params: {
  queryParams: QueryParams;
  customerExternalIdentifier: string;
}) => {
  const { queryParams, customerExternalIdentifier } = params;
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/reports/${customerExternalIdentifier}/billing-data?${queryString}`
  );
  return data;
};

export const downloadCustomerBillingData = (params: {
  queryParams: QueryParams;
  customerExternalIdentifier: string;
}) => {
  const { queryParams, customerExternalIdentifier } = params;
  const queryString = getQueryString(queryParams);
  return axios.get(
    `/reports/download-billing-data/${customerExternalIdentifier}?${queryString}`
  );
};

export const getLockers = async (params: {
  queryParams: QueryParams;
  drawerTypeCountList: DrawerTypeCount[];
  filterOutZeroValue: boolean;
}) => {
  const { queryParams, drawerTypeCountList, filterOutZeroValue } = params;
  const reqParams = getCleanParams(queryParams, filterOutZeroValue);
  const { data } = await axios.post(
    '/lockers/fetch-locker-lite-for-fleet-assignment',
    { ...reqParams, drawerTypeCountList }
  );
  return data;
};

export const getAllLockers = () => {
  return axios.get('lockers/lite');
};

export const useMapSearchApi = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);

  const { data } = await axios.get(
    `https://nominatim.openstreetmap.org/search?format=jsonv2&country=${getCountryName()}&${queryString}`
  );
  return data;
};

export const getLocalitiesByCountyId = (countyId: string) => {
  return axios.get(`/counties/${countyId}/localities`);
};

export const getDrawersRentalReport = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/reports/customer-load?${queryString}`);
  return data;
};

export const downloadDrawersRentalReport = (params: {
  queryParams: QueryParams;
}) => {
  const { queryParams } = params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pageSize, pageNumber, ...rest } = queryParams;
  const queryString = getQueryString(rest);
  return axios.get(`/reports/download-customer-load?${queryString}`);
};

export const checkClusterName = async (clusterName: string) => {
  const { data } = await axios.get(`/clusters/${clusterName}/clustername`);
  return data;
};

export const login = (params: PostLoginParams) => {
  return axios.post('account/login', params);
};

export const forgotPassword = (params: ForgotPasswordParams) => {
  return axios.post('account/forgot-password', params);
};

export const changePassword = (params: ChangePasswordParams) => {
  return axios.post('account/change-password', params);
};

export const getLockerCommandsLog = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/lockerCommands?${queryString}`);
  return data;
};

export const cancelLockerCommand = async (commandId: string) => {
  const { data } = await axios.delete(`/lockerCommands/${commandId}`);
  return data;
};

export const createLockerCommand = async (
  params: CreateLockerCommandParams
) => {
  const { data } = await axios.post('/lockerCommands', params);
  return data;
};

export const assignClusterToCustomer = async (params: AssignClusterParams) => {
  const { data } = await axios.post(
    `/clusters/${params.clusterId}/drawers/${params.customerId}`,
    params
  );
  return data;
};

export const getCustomersAssignedToCluster = async (
  queryParams: QueryParams
) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/clusters/customers/${queryParams.clusterId}?${queryString}`
  );
  return data;
};

export const getCustomerById = async (id: string) => {
  const { data } = await axios.get(`/customers/${id}`);
  return data;
};

export const unassignClusterFromCustomer = async (
  params: RemoveClusterAssignationParams
) => {
  const { data } = await axios.delete(
    `/clusters/${params.clusterId}/drawers/${params.customerId}`
  );
  return data;
};

export const getAutonomousLockerVideoEvents = async (
  queryParams: QueryParams
) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/videos/event/${queryParams.eventId}?${queryString}`
  );
  return data;
};

export const updateAccountSettings = (params: KeyValueParams) => {
  return axios.put('account/settings', {
    locale: getLocale(),
    timezone: getTimezone(),
    ...params,
  });
};

export const setLockerCapabilities = (params: KeyValueParams) => {
  const { lockerCode, ...rest } = params;
  return axios.put(`lockers/${lockerCode}/locker-capabilities`, {
    ...rest,
  });
};

export const getPlatformEvents = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/events?${queryString}`);
  return data;
};

export const getLockersIncidents = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/incidents/lockers?${queryString}`);
  return data;
};

export const getDrawersIncidents = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/incidents/drawers?${queryString}`);
  return data;
};

export const getPackagesIncidents = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/incidents/packages?${queryString}`);
  return data;
};

export const getActivitiesStatistics = async () => {
  const { data } = await axios.get(`/dashboard/authorization-statistics`);
  return data;
};

export const getUserStatistics = async () => {
  const { data } = await axios.get('/dashboard/user-statistics');
  return data;
};

export const getActiveCustomers = async () => {
  const { data } = await axios.get('/dashboard/customer-statistics/active');
  return data;
};

export const getActiveCustomersGraph = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/customer-statistics/active-by-data-range?${queryString}`
  );
  return data;
};

export const getCustomerRanking = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/customer-statistics/rated-by-data-range?${queryString}`
  );
  return data;
};

export const getActiveLockers = async () => {
  const { data } = await axios.get('/dashboard/locker-statistics/active');
  return data;
};

export const getActiveLockersByDateRange = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/locker-statistics/active-by-data-range?${queryString}`
  );
  return data;
};

export const getLockerLoadRankingByDateRange = async (
  queryParams: QueryParams
) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/locker-statistics/loaded-rate-by-data-range?${queryString}`
  );
  return data;
};

export const getPlatformDrawers = async () => {
  const { data } = await axios.get('/dashboard/drawer-statistics/active');
  return data;
};

export const getFirstLockerData = async () => {
  const queryParams = {
    pageSize: 1,
    pageNumber: 1,
    sort: 'asc',
    orderBy: 'createdAt',
  };
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/lockers?${queryString}`);
  return data;
};

export const getFleetLoadByDateRange = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);

  const { data } = await axios.get(
    `/dashboard/report-statistics/fleet-load?${queryString}`
  );
  return data;
};

export const getFleetUptimeByDateRange = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/report-statistics/fleet-uptime?${queryString}`
  );
  return data;
};

export const getClustersLoadByDateRange = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/report-statistics/cluster-load?${queryString}`
  );
  return data;
};

export const getPackageTimeInLockerByDateRange = async (
  queryParams: QueryParams
) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/report-statistics/package-time?${queryString}`
  );
  return data;
};

export const getDrawersRentalStatisticsByDateRange = async (
  queryParams: QueryParams
) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/report-statistics/owned-drawers?${queryString}`
  );
  return data;
};

export const getPackagesTotalCount = async () => {
  const { data } = await axios.get('/dashboard/package-statistics/active');
  return data;
};

export const getLatestPackages = async () => {
  const { data } = await axios.get('/dashboard/package-statistics/last-10');
  return data;
};

export const getPackagesByDateRange = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/package-statistics/active-by-data-range?${queryString}`
  );
  return data;
};

export const getLocker = async (lockerCode: string) => {
  const { data } = await axios.get(`/lockers/${lockerCode}`);
  return data;
};

export const getLowBatteryLockersCount = async () => {
  const { data } = await axios.get(
    '/dashboard/locker-statistics/low-battery-count'
  );
  return data;
};

export const getLowBatteryLockers = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(
    `/dashboard/locker-statistics/low-battery-by-search-params?${queryString}`
  );
  return data;
};

export const getDrawers = async (lockerCode: string) => {
  const { data } = await axios.get(`/lockers/${lockerCode}/drawers`);
  return data;
};
