import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';

interface SuccessSnackbarProps {
  message: string;
  handleCloseSnackbar: () => void;
}

export default function SuccessSnackbar({
  message,
  handleCloseSnackbar,
}: SuccessSnackbarProps) {
  const [open, setOpen] = React.useState(true);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    handleCloseSnackbar();
  };
  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  );

  return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={6000}>
      <SnackbarContent
        style={{
          backgroundColor: 'green',
        }}
        message={message}
        action={action}
      />
    </Snackbar>
  );
}
