import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

export interface ActionRowProps {
  rowId: string;
  handleDelete: (id: string) => void;
  handleEdit: (id: string) => void;
  isVisible?: boolean;
}

export default function ActionsRow({
  rowId,
  handleEdit,
  handleDelete,
  isVisible = true,
}: ActionRowProps) {
  const { t } = useTranslation();
  return (
    <Box>
      <Tooltip title={t('delete') as string}>
        <IconButton size='small' onClick={() => handleDelete(rowId)}>
          <DeleteIcon fontSize='inherit' />
        </IconButton>
      </Tooltip>
      {isVisible && (
        <Tooltip title={t('edit') as string}>
          <IconButton size='small' onClick={() => handleEdit(rowId)}>
            <EditIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
