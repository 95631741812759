import React from 'react';
import uniqid from 'uniqid';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import {
  getAddress,
  getColumnDrawers,
  getCustomColumn,
  getLocalityLabel,
} from '.';
import {
  CONNECTED_DRAWER_HEIGHTS,
  DB_DRAWER_COLUMN_ID_PREFIX,
  LOCKER_MODELS,
  LOCKER_TYPES,
} from '../constants';
import { MIN_WIDTH_DATAGRID, SortDirection } from '../types/general.types';
import {
  COLUMN_POSITION,
  DrawerPosition,
  DrawerType,
  IColumn,
  IDrawer,
  Locker,
  Drawer,
  ConnectedDrawerHeightKey,
  LockerTemplate,
  DrawerToSave,
} from '../types/locker.types';

export const getLocalityColumn = () => {
  const column = getCustomColumn(
    'localitate',
    'locality',
    MIN_WIDTH_DATAGRID.LOCKERS
  );
  return {
    ...column,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const address = params.row?.address || params.row?.companyAddress;
      const localityName = getLocalityLabel(address.locality);
      return (
        <div className='locality-cell' title={localityName}>
          {localityName}
        </div>
      );
    },
  };
};

export const getCountyColumn = () => {
  const column = getCustomColumn('judet', 'county', MIN_WIDTH_DATAGRID.LOCKERS);
  return {
    ...column,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const address = params.row?.address || params.row?.companyAddress;
      return address?.county?.name;
    },
  };
};

export const isConnectedLocker = (lockerType: string) => {
  return +lockerType === LOCKER_TYPES.CONNECTED_LOCKER;
};

export const getDrawerTypeId = (size: number, drawerTpeList: DrawerType[]) => {
  const result = drawerTpeList.find((el: DrawerType) => el.size === size);
  return result?.drawerTypeId || '';
};

export const getDrawerPosition = (
  drawerList: IDrawer[],
  columnPosition: string,
  drawerId: string
) => {
  const columnDrawers = drawerList.filter(
    (el) => el.columnPosition === columnPosition
  );
  return columnDrawers.findIndex((el) => el.id === drawerId);
};

export const compareColumnsPosition = (columnA: IColumn, columnB: IColumn) => {
  const aPosition = parseInt(columnA.position.substring(1), 10);
  const bPosition = parseInt(columnB.position.substring(1), 10);
  if (aPosition < bPosition) {
    return -1;
  }
  if (aPosition > bPosition) {
    return 1;
  }
  return 0;
};

export const getDrawerSizeForDisplay = (
  drawerTypeId: string,
  drawerTpeList: DrawerType[]
) => {
  const result = drawerTpeList.find(
    (el: DrawerType) => el.drawerTypeId === drawerTypeId
  );
  return result?.size || 0;
};

export const getColumnIndex = (
  columnId: string,
  columnPosition: string,
  columnList: IColumn[]
): number => {
  const columnToTheLeft = columnList.filter(
    (el) => el.position === COLUMN_POSITION.Left
  );
  const columnToTheRight = columnList.filter(
    (el) => el.position === COLUMN_POSITION.Right
  );

  if (columnPosition === COLUMN_POSITION.Left) {
    return columnToTheLeft.reverse().findIndex((el) => el.id === columnId);
  }
  return columnToTheRight.findIndex((el) => el.id === columnId);
};

export const getColumnPosition = (
  columnId: string,
  columnList: IColumn[]
): string => {
  const column = columnList.filter((el) => el.id === columnId);
  const { position } = column[0];
  const index = getColumnIndex(columnId, position, columnList);
  return `${position}${index + 1}`;
};

export const getDrawersHeight = (drawersList: IDrawer[]) => {
  return drawersList.reduce((prevVal, currentVal) => {
    return (
      prevVal +
      CONNECTED_DRAWER_HEIGHTS[
        currentVal.size.toString() as ConnectedDrawerHeightKey
      ]
    );
  }, 0);
};

export const sortLockersByClusterOrder = (lockerToSort: Locker[]) => {
  return lockerToSort.sort((a, b) => {
    return (a.clusterOrder as number) - (b.clusterOrder as number);
  });
};

export const isAutonomousLockerInCluster = (locker: Locker) => {
  return (
    locker.lockerType === LOCKER_TYPES.AUTHONOMOUS_LOCKER &&
    Object.is(locker.clusterOrder, null) === false
  );
};

export const getLockerByLockerCode = (
  lockerCode: string,
  lockerList: Locker[]
) => {
  return lockerList.find((el: Locker) => el.lockerCode === lockerCode);
};

export const getAddressColumn = () => {
  const column = getCustomColumn(
    'address',
    'address',
    MIN_WIDTH_DATAGRID.COMPLETE_ADDRESS
  );
  return {
    ...column,
    valueGetter: (params: GridValueGetterParams) => {
      return getAddress(params.row?.address);
    },
  };
};

export const sortDrawersByRow = (drawerList: IDrawer[]) => {
  const drawersToSort = structuredClone(drawerList);
  return drawersToSort.sort((a: IDrawer, b: IDrawer) => {
    return (
      parseInt((a.position as DrawerPosition).row, 10) -
      parseInt((b.position as DrawerPosition).row, 10)
    );
  });
};

export const isAutonomousLocker = (lockerType: number) => {
  return lockerType === LOCKER_TYPES.AUTHONOMOUS_LOCKER;
};

const getDrawerSize = (drawer: IDrawer | Drawer): number => {
  if ((drawer as Drawer).drawerType) {
    return (drawer as Drawer).drawerType.size;
  }
  return (drawer as IDrawer).size;
};

export const getDrawersStructure = (drawerList: (IDrawer | Drawer)[]) => {
  let drawersStructure = '';
  let startType = getDrawerSize(drawerList[0]);
  let count = 0;

  drawerList.forEach((el, index) => {
    if (getDrawerSize(el) === startType) {
      count += 1;
    } else {
      drawersStructure += `${startType}${count},`;
      count = 0;
      startType = el.size;
      count = 1;
    }
    if (index === drawerList.length - 1) {
      drawersStructure += `${startType}${count}`;
    }
  });
  return drawersStructure;
};

export const getDrawerListForDisplay = (
  drawerList: Drawer[]
): IDrawer[] | [] => {
  const drawers = drawerList
    .filter((el: Drawer) => el.isDeleted !== true)
    .map((el: Drawer) => {
      return {
        id: el.drawerId,
        size: getDrawerSize(el),
        columnId: el.board,
        customerId: el.customerId,
        companyName: el.companyName,
        position: { column: el.column, row: el.row },
        state: el.drawerState,
        isCentralUnit: el.isCentralUnit,
      };
    });
  return sortDrawersByRow(drawers);
};

export const getDrawerSizesAvailableInLocker = (
  drawerList: (Drawer | IDrawer)[]
) => {
  const centralUnitDrawers = drawerList.filter(
    (drawer) => drawer.isCentralUnit === true
  );
  const centralUnitStructure = getDrawersStructure(centralUnitDrawers);
  const locker = LOCKER_MODELS.find(
    (lockerModel) => lockerModel.centralUnitStructure === centralUnitStructure
  );
  return locker ? locker.drawerTypes : [];
};

export const getLockerTemplateDrawerList = (lockerTemplate: LockerTemplate) => {
  const templateDrawers = lockerTemplate!.columns.map((column) => {
    return getColumnDrawers(column.structure, column.id);
  });
  return templateDrawers.flat();
};

export const sortDrawersByColumnAndRow = (
  a: DrawerToSave,
  b: DrawerToSave,
  direction: SortDirection = 'asc'
) => {
  const getColumnNumber = (column: string) => parseInt(column.substring(1), 10);

  const columnA = getColumnNumber(a.column);
  const columnB = getColumnNumber(b.column);

  let result;

  if (columnA !== columnB) {
    result = direction === 'asc' ? columnA - columnB : columnB - columnA;
  } else {
    result = parseInt(a.row, 10) - parseInt(b.row, 10);
  }

  return result;
};

export const getExistingBoardIdentifiers = (drawers: Drawer[]) => {
  const boardIdentifiers: string[] = [];

  drawers.forEach((obj) => {
    if (!boardIdentifiers.includes(obj.board)) {
      boardIdentifiers.push(obj.board);
    }
  });

  return boardIdentifiers;
};

export const getColumnPositionWithIndex = (
  columnPosition: string,
  positionedColumnList: IColumn[]
) => {
  if (positionedColumnList.length === 0) {
    return `${columnPosition}1`;
  }
  const newIndex = positionedColumnList.length + 1;

  return `${columnPosition}${newIndex}`;
};

export const updateColumnsPosition = (columns: IColumn[]) => {
  return columns.map((column, index) => ({
    ...column,
    position: column.position.charAt(0) + (index + 1),
  }));
};

export const getColumnPositionByColumnId = (
  columnId: string,
  columnList: IColumn[]
) => {
  return columnList.find((el) => el.id === columnId)?.position || '';
};

export const addColumnIdToDbDrawers = (drawerList: Drawer[]) => {
  const columnIdMap: Record<string, string> = {};

  return drawerList.map((item) => {
    if (!columnIdMap[item.column]) {
      columnIdMap[item.column] = uniqid(DB_DRAWER_COLUMN_ID_PREFIX);
    }
    return { ...item, columnId: columnIdMap[item.column] };
  });
};
