import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../auth/Login';
import UnauthenticatedLayout from './UnauthenticatedLayout';
import { SuccessLoginData } from '../../types/general.types';

const ForgotPassword = React.lazy(() => import('../auth/ForgotPassword'));
const CreatePassword = React.lazy(() => import('../auth/CreatePassword'));
const ResetPassword = React.lazy(() => import('../auth/ResetPassword'));

interface Props {
  setLoginData: (data: SuccessLoginData) => void;
}

export default function UnauthenticatedRoutes({ setLoginData }: Props) {
  return (
    <Routes>
      <Route path='/' element={<UnauthenticatedLayout />}>
        <Route index element={<Login setLoginData={setLoginData} />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/create-password' element={<CreatePassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
}
