import { JwtPayload } from 'jwt-decode';
import { Address, Locker } from './locker.types';

export interface QueryParams {
  [key: string]: string | number | boolean;
}

export interface RoutesType {
  [key: string]: string;
}

export interface IPagination {
  totalCount: number;
}

export interface PaginationInfo {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export enum MIN_WIDTH_DATAGRID {
  EVENTS = 170,
  ACTIVITIES_HISTORY = 200,
  ACTION_LOGS = 160,
  ACTIONS_HISTORY = 200,
  DRAWER_STATE = 170,
  LOCKERS = 200,
  ACTIVITIES = 230,
  PACKAGES_HISTORY = 150,
  PACKAGE_SNAPSHOT = 170,
  USERS = 170,
  LOCKER_LOGS = 300,
  APP_VERSIONS = 200,
  CUSTOMERS = 170,
  CLUSTERS = 170,
  FLEET_LOAD = 170,
  COMPLETE_ADDRESS = 600,
  LOCKER_LOAD = 100,
  PACKAGE_TIME_IN_LOCKER = 180,
  ASSIGN_DRAWERS = 220,
  DEFAULT = 200,
}

export enum EVENT_TYPE {
  EVENT = 'eventId',
  LOCKER = 'lockerCode',
  DRAWER = 'drawerId',
  PACKAGE = 'packageId',
}

export enum MomentType {
  incident = 1,
  finished = 2,
}

export enum FILTER_DATE_ACTION {
  ActivitiesHistory = 'view_activities',
  Events = 'see_events',
  Incidents = 'see_incidents',
}

export type DateType = Date | null | undefined;

export enum Direction {
  RIGHT = 'right',
  LEFT = 'left',
}

export enum EventType {
  'obstructed_camera' = 1,
  'major_shock',
  'system_hack',
  'alarm',
}

export enum LOG_TYPE {
  REGULAR = 'regular',
  PAYMENT = 'payment',
  BLUETOOTH = 'bluetooth',
  UPDATER = 'updater',
}

export enum ReportType {
  FLEET_LOAD = 1,
  CLUSTERS_LOAD = 2,
  FLEET_UPTIME = 3,
  PACKAGE_TIME_AT_LOCKER = 4,
  DRAWERS_RENTAL = 5,
}

export enum DownloadReportType {
  FLEET_LOAD = 1,
  FLEET_UPTIME = 2,
  PACKAGE_WORKFLOW_TIME = 3,
  CLIENT_BILLING_DATA = 4,
  DRAWERS_RENTAL = 5,
}

export interface CustomerBillingData {
  drawerSize: number;
  ownedDays: number;
  ownedDrawers: number;
  sharedDays: number;
  sharedDrawers: number;
}

export interface PostLoginParams {
  username: string;
  password: string;
}

export interface SuccessLoginData {
  accessToken: string;
  userSettings: {
    locale: string;
    timezone: string;
    countryCode: string;
  };
}

export interface ForgotPasswordParams {
  email: string;
  setPasswordRoute: string;
  cultureName: string;
}

export interface CustomJwtPayload extends JwtPayload {
  client_user_type: string;
  client_preferred_username: string;
}

export interface ChangePasswordParams {
  userId: string;
  password: string;
  token: string;
}

export type OptionType = { label: string; value: string };

export const LockerCommandType: Record<string, number> = {
  open_drawer: 1,
  turn_on_alarm: 2,
  turn_off_alarm: 3,
  turn_on_light: 4,
  turn_off_light: 5,
  get_video_moments: 6,
  reboot_locker: 7,
  set_force_sync_pin: 8,
};

export const LockerCommandExecutionStatus: Record<string, number> = {
  pending: 1,
  downloaded_on_locker: 2,
  executed: 3,
  execution_failed: 4,
  expired: 5,
  canceled_2: 6,
};

export interface ConfirmationDialogData {
  message: string;
  rejectFunc: () => void;
  acceptFunc: () => void;
}

export interface CreateLockerCommandParams {
  commandType: number;
  eventId?: string;
  drawerId?: string;
  lockerCode?: string;
}

export interface AssignClusterParams {
  clusterId: string;
  customerId: string;
}
export interface RemoveClusterAssignationParams {
  clusterId: string;
  customerId: string;
}
export interface LockerEvent {
  eventId: string;
  eventType: number;
  eventExternalId: string;
  createdAt: string;
  lockerCode: string;
  locker: Locker;
}

export interface TimeZone {
  key: string;
  value: string;
}

export type KeyValueParams = {
  [key: string]: any;
};

export interface SmartLockDTO {
  isInitialized: boolean;
}
export interface EventDTO {
  eventId: string;
  eventType: number;
  lockerCode: string;
  createdAt: string;
}

export interface LockerIncidentDTO {
  lockerCode: string;
  lockerType: number;
  lockerState: number;
  posActive: string;
  createdAt: string;
}
export interface DrawerIncidentDTO {
  lockerCode: string;
  column: string;
  drawerState: number;
  row: string;
  updatedAt: string;
}

export interface PackageIncidentDTO {
  lockerCode: string;
  awb: string;
  packageState: number;
  workflow: number;
  updatedAt: string;
  column: string;
  row: string;
  expirationDate: string;
}

export interface MonthDTO {
  name: string;
  month: number;
  year: number;
}
export interface MonthlyCustomerTrendDTO {
  carrierCount: number;
  partnerCount: number;
  month: number;
  year: number;
}

export interface MonthlyCustomerTrendFrontDTO extends MonthlyCustomerTrendDTO {
  name: string;
}

export interface MonthlyLockerTrendsDTO {
  classicLocker: {
    count: number;
  };
  autonomousLocker: {
    count: number;
  };
  month: number;
  year: number;
}

export interface DailyReportDTO {
  date: string;
  [key: string]: any;
}

export interface TimeInLockerReportDTO {
  day: number;
  month: number;
  year: number;
  clientDropOffCourierPickUp: number;
  courierDropOffClientPickUp: number;
}

export interface MonthlyDrawersRentalTrendDTO {
  count: number;
  month: number;
  year: number;
}

export interface MonthlyDrawersRentalTrendFrontDTO
  extends MonthlyDrawersRentalTrendDTO {
  name: string;
}

export interface DashboardPackageDTO {
  lockerCode: string;
  awb: string;
  customerName: string;
  createdAt: string;
  lockerAddress: Address;
}

export interface MonthlyPackagesTrendDTO {
  canceledPackages: number;
  finishedPackages: number;
  month: number;
  year: number;
}

export interface MonthlyPackagesTrendFrontDTO extends MonthlyPackagesTrendDTO {
  name: string;
}

export interface MonthlyLockerTrendsFrontDTO extends MonthlyLockerTrendsDTO {
  name: string;
}

export interface DailyReportBackEndDTO {
  day: number;
  month: number;
  year: number;
  averageCount: number;
}

export type SortDirection = 'asc' | 'desc';
