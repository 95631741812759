import React, { useEffect, useState } from 'react';
import { Settings } from 'luxon';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from './components/common/ErrorBoundary';
import PortalRoutes from './components/common/PortalRoutes';
import { getUser, logout } from './services/authService';
import UnauthenticatedRoutes from './components/common/UnauthenticatedRoutes';
import { SuccessLoginData } from './types/general.types';
import {
  DEFAULT_LOCALE,
  DEFAULT_TIMEZONE,
  MANAGEMENT_ACCESS_TOKEN_KEY,
  MANAGEMENT_COUNTRY_CODE_STOARGE_KEY,
  MANAGEMENT_LOCALE_STOARGE_KEY,
  MANAGEMENT_TIMEZONE_STOARGE_KEY,
  UNAUTH_MANAGEMENT_LOCALE_STOARGE_KEY,
} from './constants';
import {
  delay,
  getAuthUserData,
  getLocale,
  getTimezone,
  setLocalStorageKey,
  setSessionStorageKey,
} from './utils';
import LoadingComponent from './components/common/LoadingComponent';

function App() {
  const [user, setUser] = useState<{} | null>(getUser());
  const [isLoading, setIsLoading] = useState(false);

  const { i18n } = useTranslation();

  const setLoggedUserData = async () => {
    const loggedUser = getUser();
    const locale = getLocale();
    Settings.defaultLocale = locale;
    Settings.defaultZone = getTimezone();
    if (locale) {
      i18n.changeLanguage(locale);
    }
    if (loggedUser) {
      setUser(loggedUser);
      const timeout = loggedUser.expires_at * 1000 - Date.now();
      if (timeout > 0) {
        await delay(timeout);
        setIsLoading(true);
        await delay(1000);
        logout();
      }
    }
  };

  useEffect(() => {
    setLoggedUserData();
  }, []);

  const setLoginData = (data: SuccessLoginData) => {
    const { accessToken, userSettings } = data;
    const { locale, timezone, countryCode } = userSettings;
    if (locale) {
      setSessionStorageKey(MANAGEMENT_LOCALE_STOARGE_KEY, locale);
      setLocalStorageKey(UNAUTH_MANAGEMENT_LOCALE_STOARGE_KEY, locale);
    } else {
      setSessionStorageKey(MANAGEMENT_LOCALE_STOARGE_KEY, DEFAULT_LOCALE);
    }
    if (timezone) {
      setSessionStorageKey(MANAGEMENT_TIMEZONE_STOARGE_KEY, timezone);
    } else {
      setSessionStorageKey(MANAGEMENT_TIMEZONE_STOARGE_KEY, DEFAULT_TIMEZONE);
    }
    if (countryCode) {
      setSessionStorageKey(MANAGEMENT_COUNTRY_CODE_STOARGE_KEY, countryCode);
    }

    setSessionStorageKey(
      MANAGEMENT_ACCESS_TOKEN_KEY,
      JSON.stringify(getAuthUserData(accessToken))
    );
    setLoggedUserData();
  };

  return (
    <div className='App'>
      <ErrorBoundary>
        {isLoading && <LoadingComponent />}
        {user ? (
          <PortalRoutes />
        ) : (
          <UnauthenticatedRoutes setLoginData={setLoginData} />
        )}
      </ErrorBoundary>
    </div>
  );
}

export default App;
