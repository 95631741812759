/* eslint-disable no-nested-ternary */
import React, { ReactElement, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
} from '@mui/x-data-grid';
import { useMutation, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITIES_AVAILABLE_HEADERS,
  ACTIVITIES_KEYS,
  DEFAULT_ITEMS_PER_PAGE,
  ROW_DISABLED_CLASS,
  activitiesListDataGridLegend,
} from '../../constants';
import { deleteActivityById } from '../../services/requests';
import {
  generateGridColumnsHeaderRomanian,
  getActionsColumn,
  getActivityState,
  getErrorMessage,
  getDateTimeFormat,
  isDateBeforeNow,
  allowsSmartKeyOperations,
} from '../../utils';
import ActionsRow from './ActionsRow';
import ConfirmationDialog from './ConfirmationDialog';
import DataGridComponent from './DataGrid';
import LoadingComponent from './LoadingComponent';
import ErrorDialog from './ErrorDialog';
import {
  IPagination,
  MIN_WIDTH_DATAGRID,
  QueryParams,
} from '../../types/general.types';
import { usePaginationDataGrid, useSortDataGrid } from '../../utils/hooks';
import {
  ACTIVITY_TABLE_TYPE,
  AuthorizationType,
} from '../../types/activity.types';
import SuccessSnackbar from './SuccessSnackbar';
import DataGridLegend from './DataGridLegend';

export interface ActivitiesTableParams {
  getData: (
    queryParams: QueryParams,
    id?: string
  ) => UseQueryResult<any, unknown>;
  type: string;
  id?: string;
}

export default function ActivitiesTable({
  getData,
  type,
  id,
}: ActivitiesTableParams) {
  const navigate = useNavigate();
  const initialFilterParams = {
    pageSize: DEFAULT_ITEMS_PER_PAGE,
    pageNumber: 1,
    sort: 'desc',
    orderBy: 'createdAt',
  };
  const [queryParams, setQueryParams] = React.useState(initialFilterParams);

  const { t } = useTranslation();

  const setQueryPageSize = (pageSize: number) => {
    setQueryParams((prevState) => ({ ...prevState, pageSize }));
  };
  const setQueryPageNumber = (pageNumber: number) => {
    setQueryParams((prevState) => ({ ...prevState, pageNumber }));
  };
  const { data, isLoading, error, isError, refetch } =
    type === ACTIVITY_TABLE_TYPE.global
      ? getData(queryParams)
      : getData(queryParams, id);
  const { page, pagination, onChangePageSize, onPageChange } =
    usePaginationDataGrid({
      data,
      setQueryPageNumber,
      setQueryPageSize,
    });

  const [confirmationDialogStatus, setConfirmationDialogStatus] =
    useState(false);
  const [activityIdentifier, setActivityIdentifier] = useState<string | null>(
    null
  );

  const setQuerySort = (sort: string) => {
    let sortDirection = sort;
    if (!sortDirection) {
      sortDirection = initialFilterParams.sort;
    }
    setQueryParams((prevState) => ({ ...prevState, sort: sortDirection }));
  };
  const setQueryOrderBy = (orderBy: string) => {
    let orderColumn = orderBy;
    if (!orderColumn) {
      orderColumn = initialFilterParams.orderBy;
    }
    setQueryParams((prevState) => ({ ...prevState, orderBy: orderColumn }));
  };
  const { onSortModelChange, sortModel } = useSortDataGrid({
    setQuerySort,
    setQueryOrderBy,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const deleteActivityMutation = useMutation(deleteActivityById, {
    onSuccess: () => {
      setOpenSnackbar(true);
      refetch();
    },
  });

  const handleDelete = (uid: string) => {
    setActivityIdentifier(uid);
    setConfirmationDialogStatus(true);
  };

  const handleEdit = (uid: string) => {
    navigate(`./${uid}`);
  };
  const handleDeleteConfirmation = () => {
    setConfirmationDialogStatus(false);
    if (activityIdentifier) {
      deleteActivityMutation.mutate(activityIdentifier);
    }
  };

  const handleDeleteRejection = () => {
    setConfirmationDialogStatus(false);
    setActivityIdentifier(null);
  };

  const handleView = (uid: string) => {
    navigate(`./${uid}/view`);
  };

  const getRowClassName = (params: GridRowParams) => {
    return params.row.isRevoked ? ROW_DISABLED_CLASS : '';
  };

  const getRows = () => {
    return data.data.map((el: GridRowModel) => {
      const row = { ...el };
      row.authorizationType = t(AuthorizationType[row.authorizationType]);
      row.state = t(getActivityState(row.validFrom, row.validTo));
      row.validFrom = getDateTimeFormat(row.validFrom);
      row.validTo = getDateTimeFormat(row.validTo);
      row.createdAt = getDateTimeFormat(row.createdAt);
      row.updatedAt = getDateTimeFormat(row.updatedAt);
      row.user = `${row.user.name}, ${row.user.surname}`;
      row.allowSmartKeyOperation = allowsSmartKeyOperations(
        el.authorizationType,
        el.allowSmartKeyOperation
      );
      if (!row.lockerCode) {
        row.lockerCode = t('all_lockers');
      }

      return row;
    });
  };

  const getColumns = (): GridColDef[] => {
    const columns: GridColDef[] = generateGridColumnsHeaderRomanian(
      ACTIVITIES_AVAILABLE_HEADERS,
      ACTIVITIES_KEYS,
      MIN_WIDTH_DATAGRID.ACTIVITIES,
      { field: queryParams.orderBy, sort: queryParams.sort }
    );

    const actionsColumn = getActionsColumn(
      (params: GridRenderCellParams): ReactElement => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {params.row.isRevoked === false ? (
              type === ACTIVITY_TABLE_TYPE.global ? (
                <>
                  <ActionsRow
                    rowId={params.row.authorizationId}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    isVisible={!isDateBeforeNow(params.row.validFrom)}
                  />
                  <Tooltip title={t('activity_details') as string}>
                    <IconButton
                      size='small'
                      onClick={() => handleView(params.row.authorizationId)}
                    >
                      <VisibilityIcon fontSize='inherit' />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title={t('activity_details') as string}>
                  <IconButton
                    size='small'
                    onClick={() => handleView(params.row?.authorizationId)}
                  >
                    <VisibilityIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              )
            ) : null}
          </Box>
        );
      }
    );
    return [
      ...columns,
      actionsColumn,
      { field: 'createdAt', minWidth: 200 },
      { field: 'updatedAt', minWidth: 200 },
      { field: 'validFrom', minWidth: 200 },
      { field: 'validTo', minWidth: 200 },
    ];
  };

  const handleCreateActivity = () => {
    navigate('/activities/create-activity');
  };

  useEffect(() => {}, [isError]);

  const getError = () => {
    let currentError = '';
    const errorList = {
      getDataErrorMsg: t('get_data_error'),
      deleteErrorMsg: t('delete_activity_err'),
    };
    if (isError) {
      currentError = getErrorMessage(
        error as AxiosError,
        errorList.getDataErrorMsg
      );
    } else if (deleteActivityMutation.isError) {
      const { error: deleteError } = deleteActivityMutation;
      currentError = getErrorMessage(
        deleteError as AxiosError,
        errorList.deleteErrorMsg
      );
    }
    if (currentError) {
      return <ErrorDialog status message={currentError} />;
    }
    return null;
  };

  return (
    <div>
      {(isLoading || deleteActivityMutation.isLoading) && <LoadingComponent />}
      {getError()}
      {data && (
        <>
          {openSnackbar && (
            <SuccessSnackbar
              message={t('delete_activity_success')}
              handleCloseSnackbar={handleCloseSnackbar}
            />
          )}
          <ConfirmationDialog
            status={confirmationDialogStatus}
            message={t('delete_activity_confirmation')}
            handleRejection={handleDeleteRejection}
            handleConfirmation={handleDeleteConfirmation}
          />
          <Typography variant='h4' sx={{ mb: 3 }}>
            {type === ACTIVITY_TABLE_TYPE.global
              ? t('activities')
              : t('user_activities')}
          </Typography>
          {type === ACTIVITY_TABLE_TYPE.global && (
            <Box sx={{ mb: 2 }}>
              <Button
                variant='contained'
                endIcon={<AddIcon />}
                onClick={handleCreateActivity}
                size='medium'
                sx={{ textTransform: 'capitalize' }}
              >
                {t('create_activity')}
              </Button>
            </Box>
          )}
          <DataGridComponent
            columns={getColumns()}
            rows={getRows()}
            getRowClassName={getRowClassName}
            getId={(row) => row.authorizationId}
            rowCount={(pagination as IPagination).totalCount}
            paginationMode='server'
            onChangePageSize={onChangePageSize}
            pageSize={queryParams.pageSize}
            onPageChange={onPageChange}
            page={page}
            sortingMode='server'
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
          />
          <DataGridLegend legendList={activitiesListDataGridLegend} />
        </>
      )}
    </div>
  );
}
